import styled from "styled-components";

export const Label = styled.div`
  width: 100%;
  margin-top: 50px;
  text-align: center;
  font-size: 50px;
  font-weight: 800;
  padding-bottom: 1vh;
  color: #0a4685;
  font-family: "BNPP Sans", sans-serif;
`;
Label.displayName = "Label";
