import styled from "styled-components";

export const Form = styled.div`
  padding: 50px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

Form.displayName = "Form";

export const Label = styled.label`
  font-size: 3vh;
  font-weight: 800;
  padding-bottom: 1vh;
  color: #0a4685;
  font-family: "BNPP Sans", sans-serif;
`;
Label.displayName = "Label";

export const Input = styled.input`
  display: block;
  background-color: #dcf6f2;
  padding: 10px;
  line-height: 30px;
  font-size: 5vh;
  width: calc(100% - 20px);
  border: 0;
  margin-top: 1vh;
  margin-bottom: 4vh;
  outline: 0;
`;
Input.displayName = "Input";

export const ConfirmButton = styled.button`
  cursor: pointer;
  background-color: #0a4685;
  border: none;
  border-radius: 8px;
  outline: 0;
  color: #fff;
  font-size: 3vh;
  font-weight: 300;
  margin: 1vh 0;
  width: 40%;
  padding: 10px 30px;
  line-height: 5vh;
`;
ConfirmButton.displayName = "ConfirmButton";

export const Message = styled.div`
  font-family: "BNPP Sans", sans-serif;
  ${({ isSuccess }: { isSuccess: boolean }) =>
    isSuccess ? "color: #93bd0e;" : "color: #e08d92;"}
`;
Message.displayName = "Message";

export const FieldSection = styled.div`
  margin: 10px 0;
`;
FieldSection.displayName = "FieldSection";

export const Container = styled.div`
  padding: 40px;
`;
Container.displayName = "Container";
