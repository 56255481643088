import React from "react";
import { Routes, Route } from "react-router-dom";
import { PasswordReset } from "../../pages/PasswordReset/PasswordReset";
import Activation from "../../pages/Activation/Activation";
import LandingPage from "../../pages/Landing/Landing.component";

export default function Router({ token }: { token: string | null }) {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="password-reset" element={<PasswordReset token={token} />} />
      <Route path="activation-screen" element={<Activation token={token} />} />
    </Routes>
  );
}
