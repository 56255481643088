import React from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import {
  WelcomeText,
  Background,
  PromoTitle,
  PromoSubtitle,
  PlayStore,
  AppleStore,
  StoreContainer,
} from "./Landing.style";

function LandingPage({ intl }: { intl: IntlShape }) {
  // @ts-ignore
  const androidUrl: string = intl.formatMessage({
    id: "ANDROID_URL",
    defaultMessage:
      "https://play.google.com/store/apps/details?id=com.bnpparibas.investo",
  });
  // @ts-ignore
  const iOSUrl: string = intl.formatMessage({
    id: "IOS_URL",
    defaultMessage:
      "https://itunes.apple.com/fr/app/investo-par-bnp-paribas/id1189529445?mt=8",
  });

  return (
    <Background>
      <WelcomeText>
        <FormattedMessage id="INVESTO" />
      </WelcomeText>
      <PromoTitle>
        <FormattedMessage id="PROMO_TITLE" />
      </PromoTitle>
      <PromoSubtitle>
        <FormattedMessage id="PROMO_SUBTITLE" />
      </PromoSubtitle>
      <StoreContainer>
        <a href={androidUrl}>
          <PlayStore
            src={`${process.env.PUBLIC_URL}/images/play_512x512.png`}
          />
        </a>
        <a href={iOSUrl}>
          <AppleStore
            src={`${process.env.PUBLIC_URL}/images/apple_512x512.png`}
          />
        </a>
      </StoreContainer>
    </Background>
  );
}

export default injectIntl(LandingPage);
