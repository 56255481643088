import React from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import apiConfig from "@component-studio/api-config";
import { HeaderWithLogo } from "../../components/HeaderWithLogo/HeaderWithLogo";
import { Label } from "./Activation.style";

type PropsType = {
  token: string | null | undefined;
};

export default function Activation({ token }: PropsType) {
  const [label, setLabel] = React.useState<string>("ACTIVATION_LOADING");

  React.useEffect(() => {
    axios
      .post(`${apiConfig.AUTHENTICATOR_URL}/activate`, { token })
      .then(() => {
        setLabel("ACTIVATION_SUCCESS");
      })
      .catch(() => {
        setLabel("ACTIVATION_ERROR");
      });
  }, [token]);

  return (
    <>
      <HeaderWithLogo />
      <Label>
        <FormattedMessage id={label} />
      </Label>
    </>
  );
}
