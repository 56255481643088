import React, { useState } from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import apiConfig from "@component-studio/api-config";
import { HeaderWithLogo } from "../../components/HeaderWithLogo/HeaderWithLogo";
import {
  ConfirmButton,
  FieldSection,
  Form,
  Input,
  Label,
  Message,
} from "./PasswordReset.style";

type PropsType = {
  token: string | null | undefined;
};

export function PasswordReset(props: PropsType) {
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  const onClick = async () => {
    setIsSuccessful(false);
    if (!props.token) {
      setErrorMessage("CHANGE_PASSWORD_TOKEN_INVALID");
      return;
    }

    if (password !== passwordConfirmation) {
      setErrorMessage("PASSWORD_DOES_NOT_MATCH");
      return;
    }

    if (password?.length === 0) {
      setErrorMessage("PASSWORD_TOO_SHORT");
      return;
    }

    try {
      await axios.put(
        `${apiConfig.AUTHENTICATOR_URL}/change-password-on-request`,
        { token: props.token, new_password: password }
      );
      setErrorMessage(null);
      setIsSuccessful(true);
    } catch (e: unknown) {
      const error = e as { response: { data: { key: string } } };
      setErrorMessage(error?.response?.data?.key ?? "UNKNOWN_ERROR");
    }
  };
  return (
    <>
      <HeaderWithLogo />
      <Form>
        <FieldSection>
          <Label>
            <FormattedMessage id="PASSWORD_RESET_FIELD" />
          </Label>
          <Input
            type="password"
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(event.target.value)
            }
          />
        </FieldSection>
        <FieldSection>
          <Label>
            <FormattedMessage id="PASSWORD_RESET_CONFIRMATION_FIELD" />
          </Label>
          <Input
            type="password"
            value={passwordConfirmation}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPasswordConfirmation(event.target.value)
            }
          />
        </FieldSection>
        {(errorMessage || isSuccessful) && (
          <FieldSection>
            <Message isSuccess={isSuccessful}>
              <FormattedMessage
                id={
                  isSuccessful ? "CHANGE_PASSWORD_SUCCESS" : errorMessage ?? ""
                }
              />
            </Message>
          </FieldSection>
        )}
        <ConfirmButton onClick={onClick}>
          <FormattedMessage id="PASSWORD_RESET_CONFIRM_BUTTON" />
        </ConfirmButton>
      </Form>
    </>
  );
}
