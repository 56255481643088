import React from "react";
import { FormattedMessage } from "react-intl";
import { Header, Logo, TitleText } from "./HeaderWithLogo.style";

export function HeaderWithLogo() {
  return (
    <Header>
      <Logo />
      <TitleText>
        <FormattedMessage id="INVESTO_TITLE" />
      </TitleText>
    </Header>
  );
}
