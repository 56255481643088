import styled from "styled-components";

export const Logo = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/images/investo-logo.png);
  height: 100%;
  width: 156px;
  background-repeat: no-repeat;
  background-size: contain;
`;
Logo.displayName = "Logo";

export const Header = styled.div`
  height: 20vh;
  width: 100%;
  display: flex;
  background-color: #4ad1be;
  padding: 2vh 0;
  align-items: center;
  justify-content: center;
`;
Header.displayName = "Header";

export const TitleText = styled.div`
  font-family: "BNPP Sans Cond", sans-serif;
  text-align: center;
  color: #fff;
  font-size: 10vh;
  text-transform: uppercase;
`;

TitleText.displayName = "TitleText";
