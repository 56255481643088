import styled from "styled-components";

export const WelcomeText = styled.h1`
  color: #34f9b2;
  text-align: center;
  font-size: 8em;
  font-family: "BNPP Sans Condensed", sans-serif;
`;

export const Background = styled.div`
  background: radial-gradient(
    ellipse at center,
    rgba(99, 226, 213, 1) 0%,
    rgba(47, 190, 165, 1) 100%
  );
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

export const PromoTitle = styled.h2`
  text-align: center;
  color: #ffffff;
  margin-bottom: 36px;
  font-size: 3.75rem;
  line-height: 4.688rem;
  font-family: "BNPP Rounded Light", sans-serif;
`;

export const PromoSubtitle = styled.h5`
  text-align: center;
  color: #ffffff;
  font-family: "BNPP Rounded Light", sans-serif;
`;

export const StoreContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AppleStore = styled.img`
  text-align: center;
  color: #ffffff;
  font-family: "BNPP Rounded Light", sans-serif;
  width: 180px;
`;

export const PlayStore = styled.img`
  text-align: center;
  color: #ffffff;
  font-family: "BNPP Rounded Light", sans-serif;
  width: 180px;
  margin-right: 5em;
`;
