import React from "react";
import { LanguageProvider } from "@component-studio/language-provider";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AppRouter from "./components/routing/router";

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AppWithLanguageProvider />
    </Router>
  );
}

function AppWithLanguageProvider() {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  return (
    <LanguageProvider
      projectIds={["0391e1ba-a82c-4ed3-9d31-aa48875e16a7"]}
      defaultLocale="fr_FR"
      locale={location.pathname.includes("infondo") ? "it_IT" : "fr_FR"}
    >
      <Routes>
        <Route path="/infondo/*" element={<AppRouter token={token} />} />
        <Route path="/investo/*" element={<AppRouter token={token} />} />
      </Routes>
    </LanguageProvider>
  );
}

export default App;
